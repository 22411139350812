body {
    background-color: #1c1c1c;
    font-family: Arial, sans-serif;
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.App {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

nav {
    background-color: #333;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
}

.nav-links {
    list-style: none; /* Remove bullet points */
    padding: 0;
    display: flex;
    justify-content: center; /* Center align the links */
    margin: 0;
}

.nav-links li {
    margin: 0 15px; /* Space between links */
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-weight: bold; /* Make the links bold */
    font-size: 1.1rem;
}

.nav-links a:hover {
    text-decoration: underline;
}

.main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    margin-bottom: 20px;
    text-align: center; /* Center align the title */
}

label {
    display: block;
    margin-bottom: 10px;
    text-align: center; /* Center align the label */
}

select {
    padding: 5px;
    font-size: 1rem;
    margin-bottom: 20px;
    display: block;
    margin: 0 auto; /* Center align the dropdown */
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1rem;
    min-width: 1000px;
    text-align: center; /* Center align table text */
}

thead tr {
    background-color: #fc8f00;
    text-align: center; /* Center align headers */
}

thead th {
    padding: 10px;
    border-bottom: 2px solid #444;
    cursor: pointer; /* Make the headers clickable for sorting */
    text-align: center; /* Ensure headers are centered */
    color:#ffffff;
}

tbody tr {
    border-bottom: 1px solid #444;
}

tbody tr:last-of-type {
    border-bottom: 2px solid #444;
}

tbody td {
    padding: 10px;
    text-align: center; /* Ensure cells are centered */
}

tbody td:first-child {
    text-align: left; /* Align the first column to the left */
}

tbody td span {
    color: #ccc;
    font-size: 0.9rem;
    margin-left: 5px;
}

@media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

    thead tr {
        display: none;
    }

    tr {
        margin-bottom: 10px;
    }

    td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
    }
}
