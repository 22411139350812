.power-rankings {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    text-align: center;
    overflow-x: auto; /* Allow horizontal scroll */
  }
  
  .power-rankings table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .power-rankings th, .power-rankings td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .power-rankings th {
    background-color: #444;
    color: white;
  }
  
  .power-rankings tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  