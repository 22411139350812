@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.outer-container {
    display: flex;
    max-width: 800px !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding is included in the total width */
    font-family: 'Inter';
  }
  
  .about-container {
    max-width: 800px !important; /* Limit the width of the text container */
    width: 100%;
    padding: 20px;
    color: white;
    background-color: #1c1c1c;
  }
  
  .about-container h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }
  