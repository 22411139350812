@import url('https://fonts.googleapis.com/css2?family=Graduate:wght@400;700&display=swap');

body {
  background-color: #ffffff !important;
  font-family: Arial, sans-serif;
  color: rgb(0, 0, 0) !important;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.header-text {
  font-family: 'Graduate'; /* Use your custom font here */
  color: #fc8f00; /* Your desired color */
  font-size: 45px;
  text-align: center;
  margin: 20px 0;
  top: 0;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.App {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav {
  background-color: #fc8f00 !important;
  max-width: 800px;
  width: 100%;
  padding: 10px 0;
  top: 80px;
  position: fixed;
  z-index: 1000;
}

.nav-links {
  list-style: none; /* Remove bullet points */
  padding: 0;
  display: flex;
  justify-content: center; /* Center align the links */
  margin: 0;
}

.nav-links li {
  margin: 0 15px; /* Space between links */
}

.nav-links a {
  text-decoration: none;
  color: rgb(255, 255, 255) !important;
  font-weight: bold; /* Make the links bold */
  font-size: 1.1rem;
}

.nav-links a:hover {
  text-decoration: underline;
}

.main-content {
  margin-top: 200px; /* Space below fixed header and nav */
  overflow-y: auto; /* Enable vertical scrolling for the main content */
  padding-top: 0px;
  max-height: calc(100vh - 50px); /* Limit height to viewport height minus header and nav height */
}

.table-power {
  width: 100%;
  text-align: center; /* Ensure center alignment */
  margin: 20px auto; /* Center align the container */;
  overflow-x: auto;
}

.table-stats {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  text-align: center;
  overflow-x: auto; /* Allow horizontal scroll if necessary */
}

.table-stats table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

h2 {
  margin-bottom: 20px;
  text-align: center; /* Center align the title */
  margin-top: 20px; /* Adjusts padding from nav bar to the title */
}

label {
  display: block;
  margin-bottom: 10px;
  text-align: center; /* Center align the label */
}

select {
  padding: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
  display: block;
  margin: 0 auto; /* Center align the dropdown */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
  min-width: 1000px;
  text-align: center; /* Center align table text */
}

thead tr {
  background-color: #333;
  text-align: center; /* Center align headers */
}

thead th {
  padding: 10px;
  border-bottom: 2px solid #444;
  cursor: pointer; /* Make the headers clickable for sorting */
  text-align: center; /* Ensure headers are centered */
}

tbody tr {
  border-bottom: 1px solid #444;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #444;
}

tbody td {
  padding: 10px;
  text-align: center; /* Ensure cells are centered */
}

tbody td:first-child {
  text-align: left; /* Align the first column to the left */
}

tbody td span {
  color: #ccc;
  font-size: 0.9rem;
  margin-left: 5px;
}

@media (max-width: 768px) {
  table, thead, tbody, th, td, tr {
      display: block;
  }

  thead tr {
      display: none;
  }

  tr {
      margin-bottom: 10px;
  }

  td {
      text-align: right;
      padding-left: 50%;
      position: relative;
  }

  td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
  }
}


