.team-dashboard {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  }
  
  .team-code-input {
    margin-bottom: 20px;
  }
  
  .team-code-input label {
    display: block;
    margin-bottom: 8px;
    font-size: 1.2rem;
  }

  .login-button {
    margin-left: 20px;
  }

  .add-button {
    margin-left: 20px;
  }
  
  .team-code-input input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .players-list {
    margin-top: 20px;
    display: flex !important;
    justify-content: center !important; /* Center the table horizontally */
  }
  
  .player-stats {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #000000 !important;
    border-radius: 8px;
  }
  
  .player-stats h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .stat-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;;
  }
  
  .select-player {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .stat-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .stat-input label,
  .select-player label {
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .stat-input input,
  .select-player select {
    width: 80px;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .update-button {
    margin-top: 20px; /* Move the button down */
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #fc8f00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .update-button:hover {
    background-color: #e07b00;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #fc8f00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #e07b00;
  }

  table {
    margin: -5 !important; /* Center the table */
    border-collapse: collapse;
    width: auto;
    max-width: 100%; /* Ensure the table doesn't exceed container width */
  }
  